export const serviceDetails = {
  1: {
    id: 1,
    type: "Service",
    title: "GST Registration .",
    content: {
      title: "What is GST?",
      introduction:
        "GST stands for Goods and Services Tax, which is a comprehensive indirect tax levied on the supply of goods and services in India. It was introduced in India on 1st July 2017, and it replaced multiple taxes levied by the central and state governments.",
      image: "gst/1.png",
    },
    description: {
      title: "Why is GST Registration Mandatory?",
      introduction:
        "GST registration is mandatory for businesses that meet certain criteria under the GST law. Every business that is involved in the supply of goods or services and has an annual turnover exceeding Rs. 40 lakhs (Rs. 10 lakhs for businesses in certain states) is required to register under GST.",
      image: "",
      feature: [
        {
          id: 1,
          text: " Legality: GST registration makes the business legal under the GST regime.",
        },
        {
          id: 2,
          text: " Input Tax Credit (ITC): Registered businesses are eligible to claim input tax credit on the GST paid on inputs used in the supply of goods or services, which reduces the overall tax liability.",
        },
        {
          id: 3,
          text: " Cross-state transactions: GST registration allows businesses to carry out inter-state transactions without any restrictions.",
        },
        {
          id: 4,
          text: " Compliance: Registered businesses need to comply with the GST law, which helps in improving the tax compliance culture.",
        },
        {
          id: 5,
          text: " Business growth: GST registration helps in building the business's credibility and reputation, which can lead to increased business opportunities and growth.",
        },
      ],
    },
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [
      { id: 1, url: "gst registration.pdf", name: "Document Checklist" },
    ],
    messageContext: "GSTR",
    categories: [
      {
        id: 1,
        text: "GSTR-1",
        link: "startup-income_tax_return-personal-tax-filing",
      },
      {
        id: 2,
        text: "GSTR-3B",
        link: "startup-income_tax_return-business-tax-filing",
      },
      { id: 3, text: "GSTR-9", link: "startup-income_tax_return-form-16" },
      { id: 4, text: "GSTR-4", link: "startup-income_tax_return-tax-notice" },
      { id: 5, text: "GSTR-5", link: "startup-income_tax_return-tds-filing" },
      { id: 6, text: "GSTR-6", link: "startup-income_tax_return-itr2-return" },
      { id: 7, text: "GSTR-7", link: "startup-income_tax_return-itr2-return" },
      { id: 8, text: "GSTR-8", link: "startup-income_tax_return-itr2-return" },
    ],
  },

  2: {
    id: 2,
    type: "Service",
    title: "GSTR-1.",
    content: {
      title: "What is GSTR-1?",
      introduction:
        "GSTR-1 is a monthly or quarterly return that needs to be filed by every registered business to declare the details of their outward supplies of goods or services.Some of the important details that need to be reported in GSTR-1 are:",
      image: "gst/2.png",
    },

    description: {
      title: "Need of GSTR-1 Filing  ",
      introduction:
        "GSTR-1 depends on the turnover of the business. For businesses with an annual turnover of up to Rs. 1.5 crores, GSTR-1 needs to be filed quarterly, while for businesses with a turnover above Rs. 1.5 crores, GSTR-1 needs to be filed monthly.",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the outward supplies made during the tax period, including invoice number, date, value, and GST rate.",
        },
        {
          id: 3,
          text: "Details of the credit notes and debit notes issued during the tax period.",
        },
        {
          id: 4,
          text: "Details of exports made during the tax period, including shipping bill number, date, and port of export.",
        },
        {
          id: 5,
          text: "Details of supplies made to registered persons, including their name, address, and GSTIN.",
        },
        {
          id: 6,
          text: "Details of supplies made to unregistered persons, including their name, address, and state.",
        },
        {
          id: 7,
          text: "Details of advances received against future supplies.",
        },
      ],
    },
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    messageContext: "GSTR1",
    documents: [{ id: 1, url: "gstr-1.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR2 Return",
        link: "startup-income_tax_return-itr2-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  3: {
    id: 3,
    type: "Service",
    title: "GSTR-3B",
    content: {
      title: "GSTR-3B Filing ?",
      introduction:
        "GSTR-3B is a monthly return that needs to be filed by every registered business to declare their tax liability and Input Tax Credit (ITC) claims for the relevant tax period. It is a summary return that needs to be filed by businesses, which contains information about the total tax liability, ITC claims, and the net tax payable.",
      image: "gst/3.png",
    },
    description: {
      title: "Due date of GSTR-3B Filing",
      introduction:
        "The due date for filing GSTR-3B is usually the 20th of the following month. For example, the return for the month of March needs to be filed by the 20th of April.Some of the important details that need to be reported in GSTR-3B are:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the supplies made during the tax period, including the taxable value, tax rate, and the tax amount.",
        },
        {
          id: 3,
          text: "Details of the ITC claimed during the tax period, including the ITC on inputs, capital goods, and input services.",
        },
        {
          id: 4,
          text: "Details of the exempted supplies made during the tax period.",
        },
        { id: 5, text: "Details of the tax paid on reverse charge basis." },
        { id: 6, text: "Details of any interest or late fees payable." },
        {
          id: 7,
          text: "The net tax payable or refundable after adjusting the ITC claimed.",
        },
      ],
    },
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    messageContext: "GST3B",
    documents: [{ id: 1, url: "gstr-3b.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  4: {
    id: 4,
    type: "Service",
    title: "GSTR-9",
    content: {
      title: "GSTR-9 Filing ?",
      introduction:
        "GSTR-9 is an annual return that needs to be filed by every registered business to declare the consolidated details of all the monthly or quarterly returns filed during the financial year. It is a summary return that contains information about the entire financial year and is required to be filed by every registered taxpayer except for those who have opted for the Composition Scheme or Input Service Distributors (ISDs).",
      image: "gst/4.png",
    },
    description: {
      title: "Due date of GSTR-9 Filing",
      introduction:
        "The due date for filing GSTR-9 is December 31st of the following financial year. For example, the return for the financial year 2021-22 needs to be filed by December 31st, 2022.Some of the important details that need to be reported in GSTR-9 are:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the supplies made during the tax period, including the taxable value, tax rate, and the tax amount.",
        },
        {
          id: 3,
          text: "Details of the ITC claimed during the tax period, including the ITC on inputs, capital goods, and input services.",
        },
        {
          id: 4,
          text: "Details of the exempted supplies made during the financial year.",
        },
        { id: 5, text: "Details of the tax paid on reverse charge basis." },
        {
          id: 6,
          text: "The details of any amendments made to the returns filed during the financial year.",
        },
        {
          id: 7,
          text: "Details of any demands and refunds during the financial year.",
        },
      ],
    },
    messageContext: "GST9",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-9.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  5: {
    id: 5,
    type: "Service",
    title: "GSTR-4",
    content: {
      title: "GSTR-4 Filing ?",
      introduction:
        "GSTR-4 is a quarterly return that needs to be filed by taxpayers who have opted for the Composition Scheme under GST. The Composition Scheme is an optional scheme that provides certain relaxations to small taxpayers, such as a lower rate of tax and reduced compliance requirements.",
      image: "gst/5.png",
    },
    description: {
      title: "Due date of GSTR-4 Filing",
      introduction:
        "The due date for filing GSTR-4 is the 18th of the month following the end of the quarter. For example, the return for the quarter ending June needs to be filed by July 18th.Some of the important details that need to be reported in GSTR-4 are:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the supplies made during the quarter, including the taxable value, tax rate, and the tax amount.",
        },
        { id: 3, text: "Details of the tax paid during the quarter." },
        {
          id: 4,
          text: "The details of any amendments made to the returns filed during the quarter.",
        },
        {
          id: 5,
          text: "The details of any demands and refunds during the quarter.",
        },
      ],
    },
    messageContext: "GSTR",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-4.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  6: {
    id: 6,
    type: "Service",
    title: "GSTR-5",
    content: {
      title: "GSTR-5 Filing ?",
      introduction:
        "GSTR-5 is a return that needs to be filed by non-resident taxable persons who have been registered under GST in India. Non-resident taxable persons are individuals or businesses that do not have a fixed place of business in India but are required to register under GST because they make taxable supplies in India.",
      image: "gst/6.png",
    },
    description: {
      title: "Due date of GSTR-5 Filing",
      introduction:
        "The due date for filing GSTR-5 is the 20th of the following month from the end of the tax period. For example, the return for the month of March needs to be filed by the 20th of April.Some of the important details that need to be reported in GSTR-5 are:",
      image: "6.png",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the supplies made during the tax period, including the taxable value, tax rate, and the tax amount.",
        },
        { id: 3, text: "Details of the tax paid during the tax period." },
        {
          id: 4,
          text: "The details of any amendments made to the returns filed during the tax period.",
        },
        {
          id: 5,
          text: "The details of any demands and refunds during the tax period.",
        },
      ],
    },
    messageContext: "GST5",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-5.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  7: {
    id: 7,
    type: "Service",
    title: "GSTR-6",
    content: {
      title: "GSTR-6 Filing ?",
      introduction:
        "GSTR-6 is a return that needs to be filed by Input Service Distributors (ISDs) under GST. An Input Service Distributor is a taxpayer who receives invoices for input services and distributes the input tax credit to its branches or units.",
      image: "gst/7.png",
    },
    description: {
      title: "Due date of GSTR-6 Filing",
      introduction:
        "The due date for filing GSTR-6 is the 13th of the following month from the end of the tax period. For example, the return for the month of March needs to be filed by the 13th of April.Some of the important details that need to be reported in GSTR-6 are:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the input tax credit received during the tax period.",
        },
        {
          id: 3,
          text: "Details of the input tax credit distributed to its branches or units during the tax period.",
        },
        {
          id: 4,
          text: "The details of any amendments made to the returns filed during the tax period.",
        },
        {
          id: 5,
          text: "The details of any demands and refunds during the tax period.",
        },
      ],
    },
    messageContext: "GSTR6",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-6.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  8: {
    id: 8,
    type: "Service",
    title: "GSTR-7",
    content: {
      title: "GSTR-7 Filing ?",
      introduction:
        "GSTR-7 is a return that needs to be filed by taxpayers who are required to deduct TDS (Tax Deducted at Source) under GST. Taxpayers who make certain payments such as rent, commission, professional fees, etc., are required to deduct TDS from the payments made and deposit it with the government.",
      image: "gst/8.png",
    },
    description: {
      title: "Due date of GSTR-7 Filing",
      introduction:
        "The due date for filing GSTR-7 is the 10th of the following month from the end of the tax period. For example, the return for the month of March needs to be filed by the 10th of April.Some of the important details that need to be reported in GSTR-7 are:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        { id: 2, text: "Details of the TDS deducted during the tax period." },
        {
          id: 3,
          text: "Details of the TDS deposited with the government during the tax period.",
        },
        {
          id: 4,
          text: "The details of any amendments made to the returns filed during the tax period.",
        },
        {
          id: 5,
          text: "The details of any demands and refunds during the tax period.",
        },
      ],
    },
    messageContext: "GSTR7",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-7.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  9: {
    id: 9,
    type: "Service",
    title: "GSTR-8",
    content: {
      title: "GSTR-8 Filing ?",
      introduction:
        "GSTR-8 is a return that needs to be filed by e-commerce operators who are required to collect TCS (Tax Collected at Source) under GST. E-commerce operators are entities that operate digital platforms to facilitate the supply of goods or services between suppliers and customers.",
      image: "gst/9.png",
    },
    description: {
      title: "Due date of GSTR-8 Filing",
      introduction:
        "The due date for filing GSTR-8 is the 10th of the following month from the end of the tax period. For example, the return for the month of March needs to be filed by the 10th of April.Some of the important details that need to be reported in GSTR-8 are:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Basic details of the taxpayer such as name, address, and GSTIN.",
        },
        {
          id: 2,
          text: "Details of the supplies made through the digital platform during the tax period, including the taxable value, tax rate, and the tax amount.",
        },
        { id: 3, text: "Details of the TCS collected during the tax period." },
        {
          id: 4,
          text: "The details of any amendments made to the returns filed during the tax period.",
        },
        {
          id: 5,
          text: "The details of any demands and refunds during the tax period.",
        },
      ],
    },
    messageContext: "GSTR8",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-8.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  10: {
    id: 10,
    type: "Service",
    title: "GSTR-10",
    content: {
      title: "GSTR-10 Filing ?",
      introduction:
        "GSTR-10 is a return that is required to be filed by taxpayers whose GST registration has been canceled or surrendered. It is also known as the Final Return.",
      image: "gst/10.png",
    },
    description: {
      title: "Due date of GSTR-10 Filing",
      introduction:
        "The due date for filing GSTR-10 is within three months of the date of cancellation or surrender of GST registration. The return must be filed even if no business activity has been conducted during the period for which the return is being filed.The following details need to be furnished while filing GSTR-10:",
      image: "",
      feature: [
        { id: 1, text: "GSTIN." },
        { id: 2, text: "Legal name of the taxpayer" },
        { id: 3, text: "Date of cancellation/surrender of registration" },
        {
          id: 4,
          text: "Details of inputs held in stock or in-transit on the date of cancellation/surrender",
        },
        {
          id: 5,
          text: "Details of liabilities outstanding on the date of cancellation/surrender",
        },
        { id: 6, text: "Bank account details for receiving refund, if any" },
      ],
    },
    messageContext: "GSTR10",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-10.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  11: {
    id: 11,
    type: "Service",
    title: "GST CASH REFUND",
    content: {
      title: "GST CASH REFUND ?",
      introduction:
        "Under the GST laws, a registered taxpayer can claim a refund of the excess tax paid by them. The refund can be claimed on account of various reasons such as zero-rated supplies, inverted tax structure, or accumulated input tax credit.",
      image: "gst/11.png",
    },
    description: {
      title: "Claim a refund",
      introduction:
        "To claim a refund, a taxpayer needs to file an application in Form GST RFD-01 electronically on the GST portal. The application for refund can be filed after the end of the tax period for which the refund is being claimed.",
      image: "",
      feature: [
        {
          id: 1,
          text: "The application for refund needs to be accompanied by supporting documents to establish the eligibility of the refund claim.",
        },
        {
          id: 2,
          text: "The refund amount will be credited to the bank account of the taxpayer after verification by the tax authorities.",
        },
        {
          id: 3,
          text: "If the refund claim is rejected by the tax authorities, a show-cause notice will be issued to the taxpayer.",
        },
        {
          id: 4,
          text: "The taxpayer can respond to the show-cause notice and provide additional information to support the refund claim.",
        },
        {
          id: 5,
          text: "It is important to note that the refund can only be claimed in the form of cash if the balance in the electronic credit ledger is not sufficient to offset the tax liability.",
        },
      ],
    },
    messageContext: "GSTREFUND",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gst cash refund.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  12: {
    id: 12,
    type: "Service",
    title: "GST Surrender",
    content: {
      title: "About GST Surrender ",
      introduction:
        "GST surrender refers to the process of voluntarily canceling a GST registration by a registered taxpayer. A taxpayer may opt for GST surrender for various reasons such as discontinuation of business, closure of business, merger or acquisition, or any other valid reason.",
      image: "gst/12.png",
    },
    description: {
      title: "What is the process of GST Surender?",
      introduction:
        "The process for GST surrender can be initiated by filing an application in Form GST REG-16 on the GST portal. The application needs to be signed by the authorized signatory of the taxpayer and needs to be accompanied by relevant documents to support the reason for surrender.",
      image: "",
      feature: [
        {
          id: 1,
          text: "After GST surrender is filed, the tax authorities may conduct an audit or verification to ensure that there are no pending liabilities or dues against the taxpayer.",
        },
        {
          id: 2,
          text: "The taxpayer also needs to clear all pending liabilities, file all the returns and pay any dues, interest, or penalty before applying for surrender of GST registration.",
        },
        {
          id: 3,
          text: "It will be approved after tax authorities are satisfied within 7 working days from the date of filing of the application. ",
        },
        {
          id: 4,
          text: "After GST registration is canceled, the taxpayer will not be able to collect any GST from its customers or claim any input tax credit.",
        },
        { id: 5, text: "Details of the tax paid on reverse charge basis." },
      ],
    },
    messageContext: "GSTSURRENDER",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "gstr-surrender.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  13: {
    id: 13,
    type: "Service",
    title: "Income Tax Return",
    content: {
      title: "What is Income Tax Return?",
      introduction:
        "It is a form that taxpayers in India use to file their income tax with the Income Tax Department of India. The income tax return form contains information about a taxpayer's income earned during a financial year, tax deducted at source (TDS), deductions claimed, taxes paid, and other relevant details.",
      image: "itr/1.png",
    },
    description: {
      title: "Types of ITR:",
      introduction:
        "The government of India has established various types of ITR forms, depending on the type of taxpayer and their source of income.",
      image: "",
      feature: [
        {
          id: 1,
          text: "ITR-1:(SAHAJ): This form is for individuals who have income from salary, one house property, and other sources (excluding winnings from lottery and racehorses).",
        },
        {
          id: 2,
          text: "ITR-2: This form is for individuals and HUFs (Hindu Undivided Families) who have income from salary, more than one house property, capital gains, foreign income/ assets, and other sources.",
        },
        {
          id: 3,
          text: "ITR-3: This form is for individuals and HUFs who have income from business or profession.",
        },
        {
          id: 4,
          text: "ITR-4 (SUGAM): This form is for individuals, HUFs, and firms (other than LLPs) who have income from presumptive business or profession.",
        },
        {
          id: 5,
          text: "ITR-5: This form is for LLPs (Limited Liability Partnerships), AOPs (Association of Persons), and BOIs (Body of Individuals).",
        },
        {
          id: 6,
          text: "ITR-6: This form is for companies who do not claim exemption under Section 11 of the Income Tax Act, 1961.",
        },
        {
          id: 7,
          text: "ITR-7: This form is for persons/ companies who are required to file a return under Sections 139(4A) or 139(4B) or 139(4C) or 139(4D) of the Income Tax Act, 1961.",
        },
      ],
    },
    messageContext: "ITR",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "ITR-1", link: "income_tax_return-itr1-return" },
      { id: 2, text: "ITR-2", link: "income_tax_return-itr2-return" },
      { id: 3, text: "ITR-3", link: "income_tax_return-itr3-return" },
      { id: 4, text: "ITR-4", link: "income_tax_return-itr4-return" },
      { id: 5, text: "ITR-5", link: "income_tax_return-itr5-return" },
      { id: 6, text: "ITR-6", link: "income_tax_return-itr6-return" },
      { id: 6, text: "ITR-7", link: "income_tax_return-itr7-return" },
    ],
  },
  14: {
    id: 14,
    type: "Service",
    title: "Income Tax Return - ITR-1",
    content: {
      title: "ITR-1 Filing:",
      introduction:
        "ITR-1, also known as Sahaj, is a form used for filing Income Tax Return (ITR) by individuals who have income from salary, one house property, and other sources (excluding winnings from lottery and racehorses). It is the most basic and commonly used ITR form in India.",
      image: "itr/2.png",
    },
    description: {
      title: "Keypoints to remember for ITR-1",
      introduction: "Here are some important things to know about ITR-1:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Eligibility: Individuals who have income from the sources mentioned above and whose total income for the year is up to Rs. 50 lakhs can file ITR-1.",
        },
        {
          id: 2,
          text: "Particulars: ITR-1 requires the taxpayer to provide details of their income from salary, house property, and other sources. It also asks for information related to deductions claimed under various sections of the Income Tax Act.",
        },
        {
          id: 3,
          text: "Exclusions: ITR-1 cannot be used by individuals who have income from business or profession, capital gains, or foreign assets. Additionally, if a person is a director in a company or has invested in unlisted equity shares, they cannot use ITR-1.",
        },
        {
          id: 4,
          text: "Filing options: ITR-1 can be filed online through the Income Tax Department's e-filing portal or through various online tax filing platforms.",
        },
        {
          id: 5,
          text: "Deadline: The last date to file ITR-1 for a particular financial year is typically July 31st of the following year. However, this date may be extended by the government in certain cases.",
        },
        {
          id: 6,
          text: "Penalties: Failing to file ITR-1 or filing it after the due date can result in penalties and interest charges. It is important to file the ITR within the prescribed deadline to avoid any such consequences.",
        },
      ],
    },
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    messageContext: "ITR1",
    documents: [{ id: 1, url: "itr-1.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  15: {
    id: 15,
    type: "Service",
    title: "Income Tax Return - ITR-2",
    content: {
      title: "ITR-2 Filing:",
      introduction:
        "ITR-2 is an Income Tax Return form used by individuals and Hindu Undivided Families (HUFs) who have income from salary, house property, capital gains, foreign assets or foreign income, and other sources. It is a more comprehensive form than ITR-1 and is used by taxpayers who have more complex sources of income.",
      image: "itr/3.png",
    },
    description: {
      title: "Keypoints to remember for ITR-2",
      introduction: "Here are some important things to know about ITR-2:",
      image: "",
      feature: [
        {
          id: 1,
          text: "Eligibility: ITR-2 is used by individuals and HUFs whose total income for the financial year exceeds Rs. 50 lakhs or who have earned income from more than one house property.",
        },
        {
          id: 2,
          text: "Particulars: ITR-2 requires the taxpayer to provide detailed information about their income from various sources, including salary, house property, capital gains, and foreign assets or income. It also asks for information about deductions claimed under various sections of the Income Tax Act.",
        },
        {
          id: 3,
          text: "Exclusions: ITR-2 cannot be used by individuals who have income from business or profession.",
        },
        {
          id: 4,
          text: "Filing options: ITR-2 can be filed online through the Income Tax Department's e-filing portal or through various online tax filing platforms.",
        },
        {
          id: 5,
          text: "Deadline: The last date to file ITR-2 for a particular financial year is typically July 31st of the following year. However, this date may be extended by the government in certain cases.",
        },
        {
          id: 6,
          text: "Penalties: Failing to file ITR-2 or filing it after the due date can result in penalties and interest charges. It is important to file the ITR within the prescribed deadline to avoid any such consequences.",
        },
      ],
    },
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    messageContext: "ITR2",
    documents: [{ id: 1, url: "itr-2.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  16: {
    id: 16,
    type: "Service",
    title: "Income Tax Return - ITR-3",
    content: {
      title: "ITR-3 Filing:",
      introduction:
        "ITR-3 is an income tax return form used by individuals and Hindu Undivided Families (HUFs) who have income from profits and gains from business or profession.",
      image: "itr/4.png",
    },
    description: {
      title: "Keypoints to remember for ITR-3",
      introduction: "Here are some important things to know about ITR-3:",
      image: "",
      feature: [
        {
          id: 1,
          text: "ITR-3 form is to be filed by individuals and HUFs who are partners in a partnership firm but not carrying out business as a proprietor or in a partnership firm.",
        },
        {
          id: 2,
          text: "It is also to be filed by individuals and HUFs who have income from a proprietary business or profession.",
        },
        {
          id: 3,
          text: "ITR-3 form is to be filed electronically on the Income Tax Department's website or by using offline utilities.",
        },
        {
          id: 4,
          text: "The due date for filing ITR-3 for the assessment year 2022-23 (financial year 2021-22) is September 30, 2023.",
        },
        {
          id: 5,
          text: "The ITR-3 form requires you to provide details of your income, deductions, and tax payments for the financial year. You also need to provide information about any foreign assets and income.",
        },
        {
          id: 6,
          text: "It is important to ensure that all details are accurate and complete before submitting the ITR-3 form as any errors can lead to penalties or rejection of the return.",
        },
        {
          id: 7,
          text: "If your income includes income from capital gains, house property, or other sources, you will need to file a different ITR form (ITR-2 or ITR-1) as applicable.",
        },
      ],
    },
    messageContext: "ITR3",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "itr-3.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  17: {
    id: 17,
    type: "Service",
    title: "Income Tax Return - ITR-4",
    content: {
      title: "ITR-4 Filing:",
      introduction:
        "ITR-4 is an income tax return form used by individuals and Hindu Undivided Families (HUFs) who have income from a presumptive business. It is also known as Sugam.",
      image: "itr/5.png",
    },
    description: {
      title: "Keypoints to remember for ITR-4",
      introduction: "Here are some important things to know about ITR-4:",
      image: "",
      feature: [
        {
          id: 1,
          text: "ITR-4 form is to be filed by individuals and HUFs who have income from a presumptive business. Presumptive taxation means that the tax liability is determined based on certain assumptions, such as a percentage of the turnover.",
        },
        {
          id: 2,
          text: "The presumptive business can be any business, including profession, except for those businesses that fall under the category of specified professions. Specified professions include legal, medical, engineering, architectural, accountancy, technical consultancy, interior decoration, and authorized representatives.",
        },
        {
          id: 3,
          text: "ITR-4 form is to be filed electronically on the Income Tax Department's website or by using offline utilities.",
        },
        {
          id: 4,
          text: "The due date for filing ITR-4 for the assessment year 2022-23 (financial year 2021-22) is September 30, 2023.",
        },
        {
          id: 5,
          text: "The ITR-4 form requires you to provide details of your income, deductions, and tax payments for the financial year. You also need to provide information about any foreign assets and income.",
        },
        {
          id: 6,
          text: "It is important to ensure that all details are accurate and complete before submitting the ITR-4 form as any errors can lead to penalties or rejection of the return.",
        },
        {
          id: 7,
          text: "If your income includes income from capital gains, house property, or other sources, you will need to file a different ITR form (ITR-2 or ITR-1) as applicable.",
        },
      ],
    },
    messageContext: "ITR4",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "itr-4.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  18: {
    id: 18,
    type: "Service",
    title: "Income Tax Return - ITR-5",
    content: {
      title: "ITR-5 Filing:",
      introduction:
        "ITR-5 is an income tax return form used by partnership firms, Limited Liability Partnerships (LLPs), Association of Persons (AoPs), Body of Individuals (BoIs), and other similar entities.",
      image: "itr/6.png",
    },
    description: {
      title: "Keypoints to remember for ITR-5",
      introduction: "Here are some important things to know about ITR-5:",
      image: "",
      feature: [
        {
          id: 1,
          text: "ITR-5 form is to be filed by partnership firms, LLPs, AoPs, BoIs, and other similar entities.",
        },
        { id: 2, text: "It is not to be used by individuals or HUFs." },
        {
          id: 3,
          text: "ITR-5 form is to be filed electronically on the Income Tax Department's website or by using offline utilities.",
        },
        {
          id: 4,
          text: "The due date for filing ITR-5 for the assessment year 2022-23 (financial year 2021-22) is September 30, 2023.",
        },
        {
          id: 5,
          text: "The ITR-5 form requires you to provide details of your income, deductions, and tax payments for the financial year. You also need to provide information about any foreign assets and income.",
        },
        {
          id: 6,
          text: "It is important to ensure that all details are accurate and complete before submitting the ITR-5 form as any errors can lead to penalties or rejection of the return.",
        },
        {
          id: 7,
          text: "If the partnership firm, LLP, AoP, or BoI has income from a presumptive business, they can also choose to file ITR-4 instead of ITR-5.",
        },
      ],
    },
    messageContext: "ITR5",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "itr-5.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  19: {
    id: 19,
    type: "Service",
    title: " Income Tax Return - ITR-6",
    content: {
      title: "ITR-6 Filing:",
      introduction:
        "ITR-6 is an income tax return form used by companies, other than those who claim exemption under section 11 of the Income Tax Act, 1961.",
      image: "itr/7.png",
    },
    description: {
      title: "Keypoints to remember for ITR-6",
      introduction: "Here are some important things to know about ITR-6:",
      image: "",
      feature: [
        {
          id: 1,
          text: "ITR-6 form is to be filed by companies, other than those who claim exemption under section 11 of the Income Tax Act, 1961.",
        },
        {
          id: 2,
          text: "Companies claiming exemption under section 11 of the Income Tax Act, 1961 are those engaged in charitable or religious activities.",
        },
        {
          id: 3,
          text: "ITR-6 form is to be filed electronically on the Income Tax Department's website or by using offline utilities.",
        },
        {
          id: 4,
          text: "The due date for filing ITR-6 for the assessment year 2022-23 (financial year 2021-22) is September 30, 2023.",
        },
        {
          id: 5,
          text: "The ITR-6 form requires you to provide details of your income, deductions, and tax payments for the financial year. You also need to provide information about any foreign assets and income.",
        },
        {
          id: 6,
          text: "It is important to ensure that all details are accurate and complete before submitting the ITR-6 form as any errors can lead to penalties or rejection of the return.",
        },
        {
          id: 7,
          text: "If the company has income from a presumptive business, they can choose to file ITR-4 instead of ITR-6.",
        },
      ],
    },
    messageContext: "ITR6",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "itr-6.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  20: {
    id: 20,
    type: "Service",
    title: " Income Tax Return - ITR-7",
    content: {
      title: "ITR-7 Filing:",
      introduction:
        "ITR-7 is an income tax return form used by entities who are required to furnish return under Section 139(4A) or Section 139(4B) or Section 139(4C) or Section 139(4D) of the Income Tax Act, 1961.",
      image: "itr/8.png",
    },
    description: {
      title: "Keypoints to remember for ITR-7",
      introduction: "Here are some important things to know about ITR-7:",
      image: "",
      feature: [
        {
          id: 1,
          text: "ITR-7 form is to be filed by entities who are required to furnish return under Section 139(4A) or Section 139(4B) or Section 139(4C) or Section 139(4D) of the Income Tax Act, 1961.",
        },
        {
          id: 2,
          text: "Section 139(4A) requires every person in receipt of income derived from property held under trust or other legal obligation wholly for charitable or religious purposes to file an income tax return.",
        },
        {
          id: 3,
          text: "Section 139(4B) requires political parties to file an income tax return if their income exceeds the maximum amount not chargeable to tax.",
        },
        {
          id: 4,
          text: "Section 139(4C) requires scientific research associations, news agencies, and associations or institutions referred to in Section 10(23A) to file an income tax return.",
        },
        {
          id: 5,
          text: "Section 139(4D) requires entities who are required to furnish a return of income under any other provision of the Income Tax Act, 1961 to file an income tax return.",
        },
        {
          id: 6,
          text: "ITR-7 form is to be filed electronically on the Income Tax Department's website or by using offline utilities.",
        },
        {
          id: 7,
          text: "The due date for filing ITR-7 for the assessment year 2022-23 (financial year 2021-22) is September 30, 2023.",
        },
        {
          id: 8,
          text: "The ITR-7 form requires you to provide details of your income, deductions, and tax payments for the financial year. You also need to provide information about any foreign assets and income.",
        },
        {
          id: 9,
          text: "It is important to ensure that all details are accurate and complete before submitting the ITR-7 form as any errors can lead to penalties or rejection of the return.",
        },
      ],
    },
    messageContext: "ITR7",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "itr-7.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
  21: {
    id: 21,
    type: "Service",
    title: " Income Tax Return - ITR Correction",
    content: {
      title: "ITR Correction information",
      introduction:
        "ITR correction refers to the process of rectifying any errors or omissions in an income tax return that has already been filed with the Income Tax Department. An assessee can file a revised return using the online facility of the Income Tax Department in case they discover any errors in the original return or have failed to include any relevant information.",
      image: "itr/9.png",
    },
    description: {
      title: "Keypoints to remember for ITR Correction",
      introduction:
        "Here are some important things to know about ITR Correction:",
      image: "",
      feature: [
        {
          id: 1,
          text: "An assessee can file a revised return to correct any mistakes or omissions in the original return filed.",
        },
        {
          id: 2,
          text: "ITR correction can be done only if the original return has been filed within the due date.",
        },
        {
          id: 3,
          text: "The revised return needs to be filed within the specified time limit, which is usually before the end of the relevant assessment year.",
        },
        {
          id: 4,
          text: "The process of ITR correction is similar to the process of filing the original return, and the revised return needs to be filed online on the Income Tax Department's website.",
        },
        {
          id: 5,
          text: "It is important to ensure that all details are accurate and complete before submitting the revised return as any errors can lead to penalties or rejection of the return.",
        },
        {
          id: 6,
          text: "The revised return filed will replace the original return, and the processing of the revised return will be considered as final by the Income Tax Department.",
        },
        {
          id: 7,
          text: "If any tax or interest is due as a result of the revised return, it must be paid along with the revised return.",
        },
      ],
    },
    messageContext: "ITRCORRECTION",
    price: {
      listPrice: 10000,
      mrp: 1000,
      discount: "",
    },
    documents: [{ id: 1, url: "itr-correction.pdf", name: "Document Checklist" }],
    categories: [
      { id: 1, text: "GST Registration", link: "startup-gst-gst-registration" },
      {
        id: 2,
        text: "Business Tax Filing",
        link: "startup-income_tax_return-business-tax-filing",
      },
      {
        id: 3,
        text: "Trademark Registration",
        link: "startup-intellectual_property-trademark-registration",
      },
      {
        id: 4,
        text: "Copyright",
        link: " startup-intellectual_property-copyright-registration",
      },
      {
        id: 5,
        text: "ITR5 Return",
        link: "startup-income_tax_return-itr5-return",
      },
      { id: 6, text: "Get Assistance", link: "update" },
    ],
  },
};
export const slugToDataMap = {
  "gst-gst-registration": 1,
  "gst-gstr-1": 2,
  "gst-gstr-3B": 3,
  "gst-gstr-9": 4,
  "gst-gstr-4": 5,
  "gst-gstr-5": 6,
  "gst-gstr-6": 7,
  "gst-gstr-7": 8,
  "gst-gstr-8": 9,
  "gst-gstr-10": 10,
  "gst-claim": 11,
  "gst-surrender": 12,
  income_tax_return: 13,
  "income_tax_return-itr1-return": 14,
  "income_tax_return-itr2-return": 15,
  "income_tax_return-itr3-return": 16,
  "income_tax_return-itr4-return": 17,
  "income_tax_return-itr5-return": 18,
  "income_tax_return-itr6-return": 19,
  "income_tax_return-itr7-return": 20,
  "income_tax_return-itr-correction": 21,
  "startup-startup-proprietorship": 1,
  "startup-startup-partnership": 2,
  "startup-startup-one-person-company": 3,
  "startup-startup-limited-liability-partnership": 1,
  "startup-st  0b  0artup-private-limited-company": 1,
  "startup-startup-startup-india-registration": 1,
  "startup-startup-indian-subsidiary": 1,
  "startup-startup-nidhi-company": 1,
  "startup-startup-section-8-company": 1,
  "startup-startup-usa-company-registration": 1,
  "startup-startup-digital-signature": 1,
  "startup-startup-udyam-registration": 1,
  "startup-startup-import-export-code": 1,
  "startup-startup-fssai-registration": 1,
  "startup-startup-professional-tax": 1,
  "startup-startup-llp-name-availability": 1,
  "startup-intellectual_property-trademark-registration": 1,
  "startup-intellectual_property-trademark-objection": 1,
  "startup-intellectual_property-trademark-opposition": 1,
  "startup-intellectual_property-trademark-renewal": 1,
  "startup-intellectual_property-copyright-registration": 1,
  "startup-intellectual_property-design-registration": 1,
  "startup-intellectual_property-provisional-patent": 1,
  "startup-intellectual_property-patent-registration": 1,
  "startup-income_tax_return-personal-tax-filing": 1,
  "startup-income_tax_return-business-tax-filing": 1,
  "startup-income_tax_return-tax-notice": 1,
  "startup-income_tax_return-tds-filing": 1,
  "startup-income_tax_return-form-16": 1,
  "startup-gst-gst-registration": 1,
  "startup-gst-gst-return-filing": 1,
  "startup-gst-gst-lut-filing": 1,
  "startup-gst-gst-registration-cancellation": 1,
  "startup-gst-gst-annual-return": 1,
  "startup-gst-gst-invoicing": 1,
  "startup-gst-gst-einvoicing": 1,
  "startup-gst-eway-bill": 1,
  "startup-gst-input-tax-credit": 1,
  "startup-gst-gst-software-for-accountants": 1,
  "startup-compliance-proprietorship": 1,
  "startup-compliance-partnership": 1,
  "startup-compliance-company": 1,
  "startup-compliance-llp-compliance": 1,
  "startup-compliance-payroll": 1,
  "startup-compliance-pf-registration": 1,
  "startup-compliance-pf-return-filing": 1,
  "startup-compliance-esi-registration": 1,
  "startup-compliance-add-directors": 1,
  "startup-compliance-remove-directors": 1,
  "startup-compliance-share-transfer": 1,
  "startup-compliance-dir3-kyc": 1,
  "startup-compliance-registered-office-change": 1,
  "startup-compliance-increase-authorized-capital": 1,
  "startup-compliance-winding-up-of-company": 1,
  "startup-compliance-winding-up-of-llp": 1,
  "income-tax-notice-response": 1,
};
export const slugtoData = (slug) => {
  if (slugToDataMap.hasOwnProperty(slug)) {
    return serviceDetails[slugToDataMap[slug]];
  }
  debugger;
  return null;
};
