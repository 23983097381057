import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import PageNotFound from "./pages/404/404";
import Search from "./pages/Search/Search";
import Faq from "./pages/Faq/Faq";
import Branch from "./pages/Branch/Branch";
import ServiceList from "./pages/service-list/Service-list";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Home from "./pages/Main/Home";
import { Toaster } from "react-hot-toast";
import { Sitmap } from "./pages/sitemap/Sitemap";
import BlogDetailsArea from "pages/BlogDetails/BlogDetailsArea/BlogDetailsArea";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import StaticPage from "pages/staticpage/staticpage";
import { StaticPageDataUrlMap, isRouteAllowed } from "data/routes/static-route";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:slug" element={<ServicesDetails />} />
          <Route path="/service-list/:slug" element={<ServiceList />} />
          <Route path="/faq" element={<Faq />} />
          {/* <Route path="/branch" element={<Branch />} /> */}
          {/* <Route path="/search" element={<Search />} /> */}
          {/* <Route path="/test" element={<Search />} /> */}
          {/* <Route path="/sitemap" element={<Sitmap />} /> */}
          {/* <Route path="/blog" element={<Blogs />} /> */}
          {/* <Route path="/blog/:slug" element={<BlogDetails />} /> */}
          {StaticPageDataUrlMap.map((d) => (
            <Route path={`${d}`} key={d} element={isRouteAllowed(d)?<StaticPage />:<Navigate to="notfound"/>} />

          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
}

export default App;
