import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeHeader from 'pages/Main/HomeHeader/HomeHeader';
import ContentArea from './contentArea/contentArea';
import { useLocation, useParams } from 'react-router-dom';
import { staticPageDataMap } from 'data/routes/static-route';
import Footer from 'components/shared/Footer';


const StaticPage = () => {
     const location = useLocation().pathname.replace(/\//g, "");
    // Todo: Validation
    const {meta, content} = staticPageDataMap[location]
    const {post} = content
   return (
      <>
         <PageHelmet pageTitle="Blog Details Page" />
         <HomeHeader/>
         <CommonPageHeader {...meta} />
         <ContentArea post={post}/>
         <Footer/>
      </>
   );
};

export default StaticPage;