import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <section className="about__area pb-100 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div className="about__thumb m-img">
                <img
                  src="/assets/img/about/about-2.svg"
                  className="w-100 h-100"
                  alt=""
                />
                <div className="about__shape">
                  <img src="/assets/img/about/red-shape.png" alt="" />
                </div>
              </div>
              <div className="about__list">
                <ul>
                  <li>
                    <span>
                      <i>
                        {" "}
                        <FaCheck />{" "}
                      </i>
                      Innovative ideas
                    </span>
                  </li>
                  <li>
                    <span>
                      <i>
                        {" "}
                        <FaCheck />{" "}
                      </i>
                      Professional assistance
                    </span>
                  </li>
                  <li>
                    <span>
                      <i>
                        {" "}
                        <FaCheck />{" "}
                      </i>
                      Financial advisory
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <span>Who we are</span>
                  <h3>Sky Bulls Capital</h3>
                </div>
                <p className="capitalize">
                  where we provide information and
                  resources on GST, Income Tax, and other financial services.
                <br/>
                  We are a team of experienced professionals in the field of
                  finance, with expertise in GST, Income Tax, accounting, and
                  financial planning. Our goal is to provide you with accurate
                  and up-to-date information on these topics to help you make
                  informed decisions about your finances.
                </p>

                <Link to="/services" className="z-btn ">
                  What we do
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
