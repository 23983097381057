import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { generateKey } from "./../../../utils/randomKey";
import { slugToDataMap } from "data/serviceDetail";

// TODO Make it Globally

const IMAGE_PATH = "/assets/img/services/details/";

//
const ServicesDetailsArea = ({ data }) => {
  let { categories, messageContext, content, description, price, documents } =
    data;
  let [status, setStatus] = useState(false);
  // Logic To Post Data

  const handleInquiryForm = (e) => {
    e.preventDefault();
    setStatus(true);
    const performPost = fetch("https://skybullscapital.com", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        context: messageContext,
      }),
    });
    toast.promise(performPost, {
      loading: "Loading",
      success: "Got the data",
      error: "Error While Sending The Data Kindly Connect : +91 9838111040",
    });
  };
  return (
    <>
      {data && (
        <section className="services__details pt-115 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                <div className="services__sidebar mr-50">
                  <div className="services__widget grey-bg-18 mb-40">
                    <div className="services__widget-title">
                      <h4>Categories</h4>
                    </div>
                    <div className="services__widget-content">
                      <div className="services__link">
                        <ul>
                          {/* TODO: Remove the validation for URL */}
                          {categories.map((arr) =>
                            slugToDataMap.hasOwnProperty(arr.link) ? (
                              <li key={generateKey(arr.text)}>
                                <Link
                                  reloadDocument
                                  to={`/services/${arr.link}`}
                                >
                                  {arr.text}
                                </Link>
                              </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="services__widget grey-bg-18 mb-40">
                    <div className="services__widget-title">
                      <h4>Talk To Advisor</h4>
                    </div>
                    <div className="services__widget-content">
                      <div className="services__form">
                        <form onSubmit={handleInquiryForm}>
                          <input type="text" placeholder="Name" required />
                          <input type="email" placeholder="Email" required />
                          <input type="tel" placeholder="Phone" required />
                          <input
                            type="hidden"
                            value={messageContext}
                            name="context"
                          />
                          <button
                            disabled={status}
                            type="submit"
                            className="z-btn z-btn-3 w-100"
                          >
                            Connect
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="services__widget grey-bg-18 mb-40">
                    <div className="services__widget-title">
                      <h4>Pricing Summary</h4>
                      <span>
                        <strong>List Price</strong>
                      </span>
                      :
                      <span>
                        {" "}
                        <del>{price.listPrice}</del>
                      </span>
                      <br />
                      <span>
                        <strong>Price</strong>
                      </span>
                      :<span>{price.mrp}</span>
                    </div>
                    <div className="services__widget-content"></div>
                  </div>
                  {documents.map((doc) => (
                    <div
                      key={doc.id}
                      className="services__action grey-bg-18 mb-15"
                    >
                      <a href={`/documents/${doc.url}`} target="_blank" without rel="noreferrer">
                        <i>
                          {" "}
                          <FiDownload />{" "}
                        </i>
                        {doc.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8 col-lg-8">
                <div className="services__text">
                  <h3>{content.title}</h3>
                  <p>{content.introduction}</p>
                </div>
                <div className="services__img mb-45 w-img">
                  <img src={`${IMAGE_PATH}${content.image}`} alt="pppppp" />
                </div>
                <div className="services__text">
                  <h3>{description.title}</h3>
                  <p>{description.introduction}</p>
                </div>
                <div className="services__list mb-40">
                  <ul>
                    {description.feature.map((arr) => (
                      <li key={generateKey(arr.text)}>{arr.text}</li>
                    ))}
                  </ul>
                </div>

                <button className="z-btn z-btn-3 w-100">Proceed</button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ServicesDetailsArea;
