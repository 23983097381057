import { convertToSlug } from "../utils/slug";
import { GST_SERVICE_DATA } from "./service-list-data/GST";
import { ITR_SERVICE_DATA } from "./service-list-data/ITR";
var incrementalIndex = 1;
const serviceGenerator = (
  type = "startup",
  img = "/assets/img/case/case-1.jpg",
  title,
  subtitle,
  category = "REGISTRATION",
  slug = "",
  trending
) => {
  return {
    id: incrementalIndex++,
    img,
    title,
    subtitle,
    category,
    slug: slug || convertToSlug(type + " " + category + " " + title),
    trending,
  };
};
const categories = [
  "GST",
  "INCOME_TAX_RETURN",
  "STARTUP",
  "INTELLECTUAL_PROPERTY",
  "COMPLIANCE",
  "TAX_NOTICE",
];
const featuredWorksData = [
  ...ITR_SERVICE_DATA.map(({ name, slug, trending, subtitle }) =>
    serviceGenerator(
      "startup",
      "assets/img/case/case-1.jpg",
      name,
      subtitle,
      "INCOME_TAX_RETURN",
      slug,
      trending
    )
  ),
  ...GST_SERVICE_DATA.map(({ name, slug, trending, subtitle }) =>
    serviceGenerator(
      "startup",
      "assets/img/case/case-1.jpg",
      name,
      subtitle,
      "GST",
      slug,
      trending
    )
  ),
];

export { featuredWorksData, categories };
