import React from "react";
import App from "./App";
import AllContext from "./context/AllContext";
import "react-modal-video/scss/modal-video.scss";
import "react-image-lightbox/style.css";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import "./polyfill.js";
// import { render } from 'react-snapshot';
// const rootElement = document.getElementById("root");
//   render(
// <React.Fragment>
//   <AllContext>
//     <App />
//   </AllContext>
// </React.Fragment>,
//     rootElement
//   );

import { hydrate, render } from "react-dom";
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.Fragment>
      <AllContext>
        <App />
      </AllContext>
    </React.Fragment>,
    rootElement
  );
} else {
  render(
    <React.Fragment>
      <AllContext>
        <App />
      </AllContext>
    </React.Fragment>,
    rootElement
  );
}
