export const GST_SERVICE_DATA = [
  {
    name: "GST Registration",
    slug: "gst-gst-registration",
    trending: false,
    subtitle: "Get Your Business GST-Ready with Our Hassle-Free GST Registration Service!.",
    price: 100,
    frequency: "Per Month",
  },
  {
    name: "GSTR-1 ",
    slug: "gst-gstr-1",
    trending: false,
    subtitle: "Effortlessly File Your GSTR-1 Return: The Ultimate Guide for GST-Registered Businesses.",
    price: 799,
    frequency: "Per Month",
  },
  {
    name: "GSTR-3B",
    slug: "gst-gstr-3B",
    trending: false,
    subtitle: "Streamline Your GST Return Filing with GSTR-3B: The Comprehensive Guide for Businesses.",
    price: 799,
    frequency: "Per Month",
  },
  {
    name: "GSTR-9",
    slug: "gst-gstr-9",
    trending: false,
    subtitle: "File Your GST Annual Return with Confidence: The Essential Guide to GSTR-9 Filing for Businesses.",
    price: 2199,
    frequency: "Per Month",
  },
  {
    name: "GSTR-4",
    slug: "gst-gstr-4",
    trending: false,
    subtitle: "Simplify Your GST Filing Process with GSTR-4: The Complete Guide for Composition Scheme Taxpayers.",
    price: 100,
    frequency: "Per Month",
  },
  {
    name: "GSTR-5",
    slug: "gst-gstr-5",
    trending: false,
    subtitle: "Stay Compliant with GST Regulations: A Step-by-Step Guide to GSTR-5 Filing for Non-Resident Taxpayers.",
    price: 0,
    frequency: "Per Month",
  },
  {
    name: "GSTR-6",
    slug: "gst-gstr-6",
    trending: false,
    subtitle: "Streamline Your Input Tax Credit with GSTR-6 Filing: The Comprehensive Guide for Input Service Distributors",
    price: 0,
    frequency: "Per Month",
  },
  {
    name: "GSTR-7",
    slug: "gst-gstr-7",
    trending: false,
    subtitle: "Effortlessly File Your GSTR-7 Return: The Ultimate Guide for TDS Deductors under GST",
    price: 1499,
    frequency: "Per Month",
  },
  {
    name: "GSTR-8",
    slug: "gst-gstr-8",
    trending: true,
    subtitle: "GSTR-8 Filing Made Easy: The Complete Guide for E-Commerce Operators under GST",
    price: 1999,
    frequency: "Per Month",
  },
  {
    name: "GSTR-10",
    slug: "gst-gstr-10",
    trending: true,
    subtitle: "Wrap Up Your GST Registration with Ease: The Ultimate Guide to GSTR-10 Filing for Cancelled Taxpayers.",
    price: 2499,
    frequency: "Per Month",
  },
  {
    name: "GST CASH REFUND",
    slug: "gst-claim",
    trending: true,
    subtitle: "Get Your GST Cash Refund Quickly and Easily: A Step-by-Step Guide for Businesses.",
    price: 999,
    frequency: "Per Month",
  },
  {
    name: "GST Surrender",
    slug: "gst-surrender",
    trending: true,
    subtitle: "Closing Your Business? Here's How to Surrender Your GST Registration.",
    price: 999,
    frequency: "Per Month",
  },
];


// {
//   name: string;
//   slug: string;
//   trending: boolean;
//   subtitle: string;
//   price: number;
//   frequency: "Per Month" | "One Time" | "Per Year";
// }[]