import { GST_SERVICE_DATA } from "./service-list-data/GST";
import { ITR_SERVICE_DATA } from "./service-list-data/ITR";

export const serviceListData = {
  "gst-services-and-intellectual-property": {
    header: "GST and Itellectual Property",
    subHeader:'',
    subServices: [
      ...GST_SERVICE_DATA
    ],
    priceInfo: [],
  },
  "all-tax-and-income-tax-services-and-compliance": {
    header: "ITR , Compliance and Annual Compliance",
    subHeader:'',
    subServices: [
      ...ITR_SERVICE_DATA
    ],
    priceInfo: [],
  },
  "startup-services": {
    header: "Startup Sevices",
    subHeader:'',
    subServices: [],
    priceInfo: [],
  },
  "other-technical-services-package": {
    header: "Other Services",
    subHeader:'',
    subServices: [],
    priceInfo: [],
  },
};
