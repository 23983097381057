export const StaticPageDataUrlMap = [
  "term-and-conditions",
  "refund-policy",
  "privacy-policy",
];
export const isRouteAllowed = (route) => StaticPageDataUrlMap.includes(route);

// TODO: Para with Image
export const staticPageDataMap = {
  "term-and-conditions": {
    meta: {
      title: "Terms And Condition",
      slug: "term-and-conditions",
      subtitle: "Terms And Condition",
    },
    content: {
      post: [
        { id: 5, type: "img", content: "term" , url:'/assets/img/static/terms-of-service.jpg'},

        {
          id: 1,
          type: "heading",
          size:'h3',
          content:
            "Thank you for using SKY BULLS CAPITAL. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information",
        },
        {
          id: 2,
          type: "para",
          content: [
            {
              id:1,
              type:'heading',
              content:'1.	Information Collection:',
              size:'h4'
            },
            {
              id:2,
              type:'text',
              content:'We collect personal information such as your name, address, email address, phone number, and other relevant details when you use our services. We also collect information about your financial and tax-related information.',
            }
          ],
        },
        {
          id: 2,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "2.	Use of Information:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We use your personal information to provide our services to you, including processing your GST and ITR returns, financial planning, and tax planning. We may also use your information to communicate with you regarding your GST, ITR, or financial-related matters.",
            },
          ],
        },
        {
          id: 3,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h2",
              content: "3.	Information Disclosure:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We do not disclose your personal information to third parties except as required by law. We may disclose your personal information to our employees, agents, and service providers who need the information to provide our GST, ITR, and financial services.",
            },
          ],
        },
        {
          id: 4,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "4.	Security:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We take appropriate security measures to protect your personal information from unauthorized access, disclosure, or modification. We have implemented physical, technical, and administrative safeguards to protect your personal information.",
            },
          ],
        },
        {
          id: 5,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "5.	Retention:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We retain your personal information for as long as necessary to provide our services to you and to comply with legal obligations.",
            },
          ],
        },
        {
          id: 6,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "6.	Updates:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We may update this Privacy Policy from time to time, and any changes will be posted on our website. We encourage you to review this Privacy Policy periodically.",
            },
          ],
        },
        {
          id: 7,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "7.	Contact Us:",
            },
            {
              id: 2,
              type: "text",
              content:
                "If you have any questions or concerns about our Privacy Policy or our use of your personal information, please contact us at safety@skybullscapital.com By using our website you agree to this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our website or our services.",
            },
          ],
        },
 
      ],
      authour: [],
      comment: [],
    },
  },
  "refund-policy": {
    meta: {
      title: "Refund Policy",
      slug: "refund-policy",
      subtitle: "Refund Policy",
    },
    content: {
      post: [
        { id: 5, type: "img", content: "/assets/img/static/refund-policy.jpg", url: "/assets/img/static/refund-policy.jpg" },
        {
          id: 1,
          type: "heading",
          size:'h3',
          content:
            "Thank you for using SKY BULLS CAPITAL. Please read these Terms and Conditions carefully before using our website.",
        },
        {
          id: 2,
          type: "para",
          content: [
            {
              id:1,
              type:'heading',
              content:'1.	Scope of Service',
              size:'h4'
            },
            {
              id:2,
              type:'text',
              content:'Our website provides GST, ITR, and Financial Services for individuals and businesses. Our services include GST registration, GST return filing, GST advisory, ITR filing, financial planning, tax planning, and other related services.',
            }
          ],
        },
        {
          id: 2,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "2.	User Obligations:",
            },
            {
              id: 2,
              type: "text",
              content:
                "You must provide us with accurate and complete information when using our Services. You must ensure that all the information and documents provided to us are true, accurate, and complete. Any errors or omissions in the information provided may result in delayed or incorrect processing of your GST, ITR, or financial application or returns.",
            },
          ],
        },
        {
          id: 3,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h2",
              content: "3.	Payment:",
            },
            {
              id: 2,
              type: "text",
              content:
                "Payment for our GST, ITR, and Financial Services must be made in advance, and our fees are non-refundable except as provided in our Refund Policy.",
            },
          ],
        },
        {
          id: 4,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "4.	Intellectual Property Rights",
            },
            {
              id: 2,
              type: "text",
              content:
                "All content on our website, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, and software, is the property of SKY BULLS CAPITAL and is protected by Indian and international copyright laws.",
            },
          ],
        },
        {
          id: 5,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "5.	Disclaimer:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We make no warranties, express or implied, regarding the accuracy or completeness of the information provided on our website. We shall not be liable for any damages whatsoever, including but not limited to direct, indirect, incidental, punitive, and consequential damages, arising out of or in connection with the use or inability to use our services.",
            },
          ],
        },
        {
          id: 6,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "6.	Confidentiality:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We maintain the confidentiality of all information provided to us by our clients and shall not disclose any such information to any third party, except as required by law.",
            },
          ],
        },
        {
          id: 7,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "7.	Governing Law:",
            },
            {
              id: 2,
              type: "text",
              content:
                "These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of India. By using our website and GST, ITR, and Financial Services, you agree to these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use our website or our services.",
            },
          ],
        },
 
        
      ],
      authour: [],
      comment: [],
    },
  },
  "privacy-policy": {
    meta: {
      title: "Priacy Policy",
      slug: "privacy-policy",
      subtitle: "Priacy Policy",
    },
    content: {
      post: [
        { id: 5, type: "img", content: "/img/static/privacy-policy.jpg" , url:"/assets/img/static/privacy-policy.jpg"},
        {
          id: 1,
          type: "heading",
          size:'h3',
          content:
            "Thank you for using SKY BULLS CAPITAL. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information",
        },
        {
          id: 2,
          type: "para",
          content: [
            {
              id:1,
              type:'heading',
              content:'1.	Information Collection:',
              size:'h3'
            },
            {
              id:2,
              type:'text',
              content:'We collect personal information such as your name, address, email address, phone number, and other relevant details when you use our services. We also collect information about your financial and tax-related information.',
            }
          ],
        },
        {
          id: 2,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "2.	Use of Information:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We use your personal information to provide our services to you, including processing your GST and ITR returns, financial planning, and tax planning. We may also use your information to communicate with you regarding your GST, ITR, or financial-related matters.",
            },
          ],
        },
        {
          id: 3,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h2",
              content: "3.	Information Disclosure:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We do not disclose your personal information to third parties except as required by law. We may disclose your personal information to our employees, agents, and service providers who need the information to provide our GST, ITR, and financial services.",
            },
          ],
        },
        {
          id: 4,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "4.	Security:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We take appropriate security measures to protect your personal information from unauthorized access, disclosure, or modification. We have implemented physical, technical, and administrative safeguards to protect your personal information.",
            },
          ],
        },
        {
          id: 5,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "5.	Retention:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We retain your personal information for as long as necessary to provide our services to you and to comply with legal obligations.",
            },
          ],
        },
        {
          id: 6,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "6.	Updates:",
            },
            {
              id: 2,
              type: "text",
              content:
                "We may update this Privacy Policy from time to time, and any changes will be posted on our website. We encourage you to review this Privacy Policy periodically.",
            },
          ],
        },
        {
          id: 7,
          type: "para",
          content: [
            {
              id: 1,
              type: "heading",
              size: "h3",
              content: "7.	Contact Us:",
            },
            {
              id: 2,
              type: "text",
              content:
                "If you have any questions or concerns about our Privacy Policy or our use of your personal information, please contact us at safety@skybullscapital.com By using our website you agree to this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our website or our services.",
            },
          ],
        },
 
        
      ],
      authour: [],
      comment: [],
    },
  },
};
