export const questionAnswerData = [
    {
        id:1,
        question:'Can you help my business grow?',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    },
    {
        id:2,
        question:'What are your best interest rates?',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    },
    {
        id:3,
        question:'How do you calculate cost of services?',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    },
  
    {
        id:5,
        question:' you help my business grow?',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    },
    {
        id:6,
        question:'Can you  my business grow?',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    },
    {
        id:7,
        question:'Can you help my  grow?',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    },
    {
        id:8,
        question:'Can you help my business Qpop? ',
        answer:" Et voluptate sint quis nulla culpa excepteur ut ullamco tempor irure ipsum. Quis aliquip eiusmod laborum voluptate nisi veniam exercitation culpa dolore qui esse commodo. "
    }

]