import React from "react";
import PropTypes from "prop-types";

const Author = ({ img, name, designation, introduction }) => {
  return (
    <div className="blog__author mb-95 d-sm-flex">
      <div className="blog__author-img mr-30">
        <img src="/assets/img/static/author/author-1.jpg" alt="" />
      </div>
      <div className="blog__author-content">
        <h5>Sandeep Roy</h5>
        <span>Author</span>
        <p>
          I said cracking goal down the pub blag cheeky bugger at public school
          A bit of how's your father boot.!
        </p>
      </div>
    </div>
  );
};

export default Author;
Author.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  designation: PropTypes.string,
  introduction: PropTypes.string,
};
