import React from "react";
import BlogText from "./BlogText";

const Paragraph = ({ content, containerClass }) => {
  const output = [];
  content.forEach((element) => {
    switch (element.type) {
      case "heading":
        let El = element.size;
        output.push(<El> {element.content}</El>);
        break;
      case "text":
        output.push(<BlogText content={element.content} />);
        break;
      default:
    }
  });

  return <div className="blog__text mb-40">{output}</div>;
};

export default Paragraph;
