import React from 'react';
import Author from 'components/BlogContentLayout/Author';
import SocialShare from './../../../components/BlogContentLayout/SocialShare';
import Quote from 'components/BlogContentLayout/Quote';
import BlogImage from 'components/BlogContentLayout/BlogImage';
import Paragraph from 'components/BlogContentLayout/Paragraph';
import BlogText from 'components/BlogContentLayout/BlogText';
import BlogHeading from 'components/BlogContentLayout/BlogHeading';

const ContentArea = ({post}) => {
   const output= []

   post.map((sections) => {
      switch (sections.type){
         case 'para':
            output.push(<Paragraph {...sections}/>)
            break;
         case 'img':
            output.push(<BlogImage {...sections}/>)
            break;
         case 'text':
            output.push(<BlogText  {...sections}/>)
            break;
         case 'heading':
               output.push(<BlogHeading  {...sections}/>)
               break;
         case 'qoute':
            output.push(<Quote {...sections}/>)
            break;
          default:
            break;
      }
   })
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-xl-8 col-lg-8 col-sm-12">
                     <div className="blog__details-wrapper mr-50">
                       {output}
                        <SocialShare/>
                       <Author/>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContentArea;