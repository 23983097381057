import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import HomePricing from './../Home/HomePricing/HomePricing';
import HomeHeader from '../Main/HomeHeader/HomeHeader';
import HomeAchievement from './../Main/HomeAchievement/HomeAchievement';
import AboutFaqSection from '../Main/AboutFaqSection/AboutFaqSection';
import Footer from 'components/shared/Footer';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="About SkyBullsCapital" />
         <HomeHeader/>
         <CommonPageHeader title="About Us" subtitle="About" />
         <HomeAbout/>
         <AboutFaqSection/>
         <HomeAchievement/>
         <HomePricing/>
         {/* <HomeBrands/> */}
         <Footer/>
      </>
   );
};

export default About;