import SectionTitle from "components/shared/SectionTitle";
import React from "react";
import { Link } from "react-router-dom";

export const ServiceTile = ({ data }) => {
  return (
    <section className="servicetile__area pb-200 pt-100 container-fluid">
      <SectionTitle title={'Services'} heading={'Our Services'}/>
      {data.length ?
      <div className="row m-0">
        {data.map(({ name, slug, trending, subtitle }) => (
          <div className="grid-item-small">
            <Link to={`/services/${slug}`} className="link-btn">
              <div className="servicetile__item servicetile__item-2 white-bg fix mb-30 ">
                <div className="servicetile__thumb-2"></div>
                <div className="servicetile__content-2">
                  <h3>{name}</h3>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
:'Will be added soon'}
    </section>
  );
};
