import React from "react";
import PropTypes from "prop-types";
const Quote = ({text, author}) => {
  return (
    <div className="blog__quote mb-40 p-relative white-bg fix">
      <div className="blog__quote-line">
        <img className="line-1" src="/assets/img/icon/blog/line-1.png" alt="" />
        <img className="line-2" src="/assets/img/icon/blog/line-2.png" alt="" />
      </div>
      <div className="quote mb-10">
        <img src="/assets/img/icon/blog/quote-1.png" alt="" />
      </div>
      <div className="quote-2 p-absolute">
        <img src="/assets/img/icon/blog/quote-2.png" alt="" />
      </div>
      <blockquote>
        <p>
          Tosser argy-bargy mush loo at public school Elizabeth up the duff
          buggered chinwag on your bike mate don't get shirty with me super,
          Jeffrey bobby Richard cheesed off spend a penny a load of old tosh
          blag horse.
        </p>
      </blockquote>
      <h4>Shahnewaz Sakil</h4>
    </div>
  );
};

export default Quote;
// Quote.propTypes = {
//     text: PropTypes.string,
//     quote: PropTypes.string
//   };
  