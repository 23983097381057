import React from "react";

const BlogImage = ({
  url = "/assets/img/blog/details/b-d-1.jpg",
  alt = "",
}) => {
  return (
    <div>
      <div className="blog__details-thumb w-img mb-45">
        <img src={url} alt={alt} />
      </div>
    </div>
  );
};

export default BlogImage;
