export const ITR_SERVICE_DATA = [
  {
    name: "Income Tax Return",
    slug: "income_tax_return",
    trending: false,
    subtitle:
      "File Your Income Tax Return with Confidence: Everything You Need to Know About ITR Forms and Filing Process.",
  },
  {
    name: "ITR-1 Return",
    slug: "income_tax_return-itr1-return",
    trending: false,
    subtitle:
      "File Your Income Tax Return Hassle-Free with ITR-1 Form: Everything You Need to Know.",
    price: 699,
  },

  {
    name: "ITR-2 Return",
    slug: "income_tax_return-itr2-return",
    trending: false,
    subtitle:
      "ITR-2 Form: The Comprehensive Guide to Filing Your Income Tax Return for Individuals and HUFs.",
    price: 1299,
  },

  {
    name: "ITR-3 Return",
    slug: "income_tax_return-itr3-return",
    trending: false,
    subtitle:
      "Maximize Your Tax Savings with ITR-3 Form: The Ultimate Guide for Business and Profession Income Tax Returns.",
    price: 1299,
  },

  {
    name: "ITR-4 Return",
    slug: "income_tax_return-itr4-return",
    trending: false,
    subtitle:
      "ITR-4 Form Simplified: File Your Income Tax Return for Presumptive Income with Ease.",
    price: 1599,
  },

  {
    name: "ITR-5 Return",
    slug: "income_tax_return-itr5-return",
    trending: false,
    subtitle:
      "ITR-5 Form: The Complete Guide to Filing Your Income Tax Return for LLPs and Partnership Firms.",
    price: 1799,
  },

  {
    name: "ITR-6 Return",
    slug: "income_tax_return-itr6-return",
    trending: false,
    subtitle:
      "Streamline Your Corporate Tax Filing with ITR-6 Form: The Comprehensive Guide for Companies.",
  },

  {
    name: "ITR-7 Return",
    slug: "income_tax_return-itr7-return",
    trending: true,
    subtitle:
      "ITR-7 Form Demystified: The Essential Guide for Filing Your Income Tax Return as a Trust or Association.",
    price: 0,
  },
  {
    name: "ITR Correction",
    slug: "income_tax_return-itr-correction",
    trending: true,
    subtitle: "Streamline your Taxes with our GST Registration and ITR Correction Services!.",
  },
];
