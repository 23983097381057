import React from "react";
import { FaFacebookF, FaTwitter, FaVimeoV } from "react-icons/fa";
const SocialShare = () => {
  return (
    <div className="blog__share d-flex align-items-center mb-30">
      <span>Share : </span>
      <div className="blog__social theme-social d-inline-block">
        <ul>
          <li>
            <a href="#">
              <i>
                <FaFacebookF />
              </i>
              <i>
                <FaFacebookF />
              </i>
            </a>
          </li>
          <li>
            <a href="#">
              <i>
                <FaTwitter />{" "}
              </i>
              <i>
                <FaTwitter />{" "}
              </i>
            </a>
          </li>
          <li>
            <a href="#">
              <i>
                <FaVimeoV />{" "}
              </i>
              <i>
                <FaVimeoV />{" "}
              </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialShare;
