import React from "react";

const BlogHeading = ({ content, size }) => {
  const Element = size;

  return (
    <>
      <Element> {content}</Element>
    </>
  );
};

export default BlogHeading;
