import React from "react";

const SectionTitle = ({ title, heading }) => {
  return (
    <div
      className="section__title section__title-3 text-center mb-90 wow fadeInUp"
      data-wow-delay=".2s"
    >
      <span>{title}</span>
      <h2>{heading}</h2>
    </div>
  );
};

export default SectionTitle;
