import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageHelmet from "../../components/shared/PageHelmet";
import ServicesDetailsArea from "./ServicesDetailsArea/ServicesDetailsArea";
import ServicesDetailsHeader from "./ServicesDetailsHeader/ServicesDetailsHeader";
import { slugtoData } from "../../data/serviceDetail";
import HomeHeader from "./../Main/HomeHeader/HomeHeader";
import Footer from "components/shared/Footer";
const ServicesDetails = () => {
  let { slug } = useParams();
  const [pageData, setPageData] = useState(slugtoData(slug));

  return (
    <>
      {pageData ? (
        <>
          {" "}
          <PageHelmet pageTitle="Services Details Page" />
          <HomeHeader />
          <ServicesDetailsHeader data={pageData} />
          <ServicesDetailsArea data={pageData} />
          <Footer />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ServicesDetails;
